<template>
  <div class="payment-cancel">
    <div class="cancel-container">
      <div class="cancel-icon">
        <i class="fa fa-times-circle"></i>
      </div>
      <h1>Payment Cancelled</h1>
      <p class="message">Your premium subscription was not activated.</p>

      <div class="actions">
        <button @click="upgrade" class="button primary">Try Again</button>
        <router-link to="/" class="button secondary"
          >Go to Homepage</router-link
        >
      </div>

      <div class="support">
        <p>
          Having issues?
          <a href="mailto:support@face-spy.com">Contact Support</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PremiumService from "@/services/PremiumService";

export default {
  name: "PaymentCancel",
  methods: {
    upgrade() {
      PremiumService.redirectToCheckout();
    },
  },
};
</script>

<style scoped>
.payment-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  padding: 20px;
  background: linear-gradient(
    135deg,
    rgba(22, 22, 26, 0.9) 0%,
    rgba(16, 16, 22, 0.9) 100%
  );
}

.cancel-container {
  max-width: 600px;
  width: 100%;
  background-color: rgba(30, 30, 38, 0.85);
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  padding: 40px;
  text-align: center;
  color: white;
}

.cancel-icon {
  font-size: 80px;
  margin-bottom: 20px;
  color: #f44336;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #f44336;
}

.message {
  font-size: 18px;
  margin-bottom: 30px;
}

.actions {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
}

.primary {
  background: linear-gradient(135deg, #ffd700 0%, #ff8c00 100%);
  color: white;
}

.secondary {
  background: rgba(255, 255, 255, 0.15);
  color: white;
}

.primary:hover,
.secondary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.support {
  margin-top: 30px;
  font-size: 14px;
  opacity: 0.7;
}

.support a {
  color: #4a74dc;
  text-decoration: none;
}

.support a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .cancel-container {
    padding: 30px 20px;
  }

  .cancel-icon {
    font-size: 60px;
  }

  h1 {
    font-size: 28px;
  }

  .actions {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
